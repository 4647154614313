<template>
    <div style="background-color: #fff">
        <div class="pt-3">
            <search @condition="condition" :fields="searchFields"></search>
        </div>
        <div class="mt-3">
            <el-table
                :data="tableData"
                stripe
                border
                @sort-change="sortChange"
                :default-sort="page.sort"
                style="width: 100%">
                <el-table-column v-for="item in showFields"
                                 :key="item.name"
                                 :prop="item.name"
                                 :sortable="item.sortable"
                                 :label="item.title"
                                 :width="item.width">
                </el-table-column>
                <el-table-column
                    prop="platform"
                    label="平台"
                    width="90"
                >
                    <template slot-scope="scope">
                        <el-tag
                            :type="scope.row.platform === 'ios' ? 'primary' : 'success'"
                            disable-transitions>{{ scope.row.platform }}
                        </el-tag>
                    </template>
                </el-table-column>
                <el-table-column
                    prop="device"
                    label="设备"
                    align="center"
                    width="60"
                >
                    <template slot-scope="scope">
                        <el-link @click="showDevice(scope.row.device)" type="success" icon="el-icon-info"></el-link>
                    </template>
                </el-table-column>
                <el-table-column
                    prop="create_time"
                    label="创建时间"
                    width="200"
                    :sortable="true"
                >
                </el-table-column>
                <el-table-column
                    prop="replied"
                    label="回复"
                    align="center"
                    width="90"
                >
                    <template slot-scope="scope">
                        <el-tag v-if="scope.row.replied===1"
                                type="success"
                        >已回复
                        </el-tag>
                        <el-tag v-else-if="scope.row.replied===100"
                                type="info"
                        >不回复
                        </el-tag>
                        <el-tag v-else-if="scope.row.replied===0"
                                type="warning"
                        >未回复
                        </el-tag>
                        <el-tag v-else
                        >未知
                        </el-tag>
                    </template>
                </el-table-column>
                <el-table-column
                    label="操作"
                    align="center"
                    width="90"
                >
                    <template slot-scope="scope">
                        <el-button v-access="{url:baseUrl,method:'PUT'}" icon="el-icon-s-comment" size="mini"
                                   @click="replyUser(scope.$index)"></el-button>
                        <!--                        <el-link type="primary" icon="el-icon-s-comment" @click="replyUser(scope.$index)"></el-link>-->
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <div class="pb-3 mt-3">
            <el-pagination
                @size-change="sizeChange"
                @current-change="pageChange"
                :current-page="page.pageNumber"
                :page-sizes="[10, 15, 30, 50]"
                :page-size="page.pageSize"
                :background="true"
                layout="sizes,prev, pager,next,total"
                :total="page.total">
            </el-pagination>
        </div>
        <el-dialog
            title="回复用户"
            :visible.sync="commentDialogVisible"
            width="800px"
            :show-close="false"
            :close-on-press-escape="false"
            :close-on-click-modal="false"
        >
            <el-form ref="commentForm" :rules="commentRules"
                     @submit.native.prevent="sendMsg" :model="replyForm" label-width="100px">
                <div class="commentInfo">
                    <el-form-item label="用户:">
                        <span>{{ currentComment.username }}</span>
                    </el-form-item>
                    <el-form-item label="内容:">
                        <span>{{ currentComment.content }}</span>
                    </el-form-item>
                    <el-form-item label="设备:">
                        <span>{{ currentComment.device }}</span>
                    </el-form-item>
                </div>
                <el-form-item label="是否回复:">
                    <el-switch
                        :disabled="this.currentComment.replied!==0"
                        v-model="replyForm.replied"
                        inactive-text="无需回复"
                        active-text="回复用户"
                    >
                    </el-switch>
                </el-form-item>
                <template v-if="replyForm.replied">
                    <!--                    <el-form-item label="回复姓名:" prop="reply_title">-->
                    <!--                        <el-input :disabled="this.currentComment.replied>0" v-model="replyForm.reply_title" placeholder="姓名"></el-input>-->
                    <!--                    </el-form-item>-->
                    <el-form-item label="绑定客服人员">
                        <el-radio-group v-model="replyForm.customer_service">
                            <el-radio label="88888888">Android 客服</el-radio>
                            <el-radio label="66666666">IOS 客服</el-radio>
                        </el-radio-group>
                    </el-form-item>
                    <el-form-item label="回复内容:" prop="reply_message">
                        <el-input :autosize="{ minRows: 3, maxRows: 5}" :disabled="this.currentComment.replied>0" v-model="replyForm.reply_message"
                                  type="textarea" placeholder="请输入回复内容"></el-input>
                    </el-form-item>

                    <!--                    <el-form-item label="短信内容:">-->
                    <!--                        <div>{{msgContent}}</div>-->
                    <!--                        <div class="text-right">-->
                    <!--                            <span>{{msgContent.length}}</span>-->
                    <!--                        </div>-->
                    <!--                    </el-form-item>-->
                </template>
            </el-form>
            <span slot="footer" class="dialog-footer">
    <el-button :loading="$store.state.loading" @click="commentDialogVisible = false">取 消</el-button>
    <el-button :loading="$store.state.loading" type="primary" v-show="currentComment.replied===0"
               @click="sendMsg">确 定</el-button>
  </span>
        </el-dialog>
    </div>
</template>
<script>
import F from '@/fields/fields';
import search from "@/components/common/search";

export default {
    name: "userApp",
    components: {
        search
    },
    mounted() {

    },
    data: function () {
        return {
            baseUrl: '/manage/comment/index',
            page: {
                search: {},
                sort: {prop: 'create_time', order: 'descending'},
                total: 1,
                pageSize: 10,
                pageNumber: 1,
            },
            index: 0,
            commentRules: {
                // reply_title: [
                //     {required: true, message: '回复姓名不可以为空', trigger: 'blur'},
                // ],
                reply_message: [
                    {required: true, message: '回复信息不可以为为空', trigger: 'blur'},
                ],
            },
            replyForm: {
                replied: true,
                // reply_title: '',
                reply_message: '',
                customer_service:'',
            },
            currentComment: {},
            commentDialogVisible: false,
            tableData: [],
        }
    },
    computed: {
        // msgContent:function(){
        //     return  this.replyForm.reply_title+'您好，感谢您使用BIGEAMP，您提交的'+this.replyForm.reply_message+'已修复，修复内容会在下一个版本更新，再次感谢您的使用，谢谢';
        // },
        searchFields: function () {
            return F.search('comment', 'search');
        },
        showFields: function () {
            return F.show('comment', 'show');
        }
    },
    methods: {
        condition: function (search) {
            this.page.search = search;
            this.page.pageNumber = 1;
            this.search();
        },
        search: function () {
            this.$axios.get(this.baseUrl, {
                params: this.page,
            }).then(data => {
                data = data.data;
                this.page.total = data.data.total;
                this.page.pageNumber = data.data.pageNumber;
                this.page.pageSize = data.data.pageSize;
                this.tableData = data.data.data;
            });
        },
        sortChange: function (column) {
            if (!column.order) return;
            this.page.sort = {prop: column.prop, order: column.order};
            this.search();
        },
        sizeChange: function (size) {
            this.page.pageSize = size;
            this.search();
        },
        pageChange: function (page) {
            this.page.pageNumber = page;
            this.search();
        },
        filterPlatform(value, row) {
            return row.tag === value;
        },
        replyUser: function (index) {
            this.currentComment = this.tableData[index];
            this.replyForm.reply_message = this.currentComment.reply_message;
            // this.replyForm.reply_title= this.currentComment.reply_title;
            this.index = index;
            this.replyForm.customer_service = this.currentComment.customer_service||'';
            // console.log(this.currentComment);
            if (!this.currentComment.customer_service) {
                //如果没有指定客服，那么根据平台来指定
                switch (this.currentComment.platform) {
                    case 'android':
                        this.replyForm.customer_service = '88888888';
                        break;
                    case 'ios':
                        this.replyForm.customer_service = '66666666';
                        break;
                }
            }
            // console.log(this.replyForm.customer_service);
            this.replyForm.replied = [0, 1].indexOf(this.currentComment.replied) !== -1;
            this.commentDialogVisible = true;
        },
        showDevice: function (str) {
            this.$alert(str&&str.replace(/\n/g,"<br/>") || '无', '设备详情', {
                confirmButtonText: '我知道了',
                dangerouslyUseHTMLString:true,
            });
        },
        sendMsg: function () {
            // console.log(e);
            this.$refs.commentForm.validate(validate => {
                if (!validate) return;
                let reply = JSON.parse(JSON.stringify(this.replyForm));
                reply.replied = reply.replied ? 1 : 100;
                this.$axios.put(this.baseUrl + '/' + this.currentComment.comment_id + '/reply', reply).then((commentData) => {
                    this.commentDialogVisible = false;
                    this.$message.success('回复成功!');
                    this.tableData.splice(this.index, 1, commentData.data);
                    // this.currentComment=Object.assign({},this.currentComment,commentData.data);
                });
            })
        }
    }
}
</script>

<style scoped lang="less">
.commentInfo {
    /*color: #555;*/
    /*border: 1px solid #ccc;*/
    border-radius: 5px;
    margin-bottom: 10px;

    ::v-deep {
        .el-form-item {
            //margin-bottom: 0;
        }
    }
}
</style>
